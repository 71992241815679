import superagentPromise from 'superagent-promise';
import _superagent from 'superagent';
import getConfig from "next/config";
const { publicRuntimeConfig } = getConfig();

const superagent = superagentPromise(_superagent, global.Promise);

const API_ROOT = publicRuntimeConfig.apiUrl;
const API_KEY = "Basic YXBpOkNiVVlMM25ZbDFOVGRlNnp1NUho";

const encode = encodeURIComponent;
let token = null;
const tokenPlugin = request => {
    if (token) {
        request.set('Token', `${token}`);
    }
    request.set("Authorization", `${API_KEY}`);
}

const api = {
    get: (url, body) =>
        superagent.get(encodeURI(`${API_ROOT}${url}`), body).use(tokenPlugin),
    put: (url, body) =>
        superagent.put(encodeURI(`${API_ROOT}${url}`), body).use(tokenPlugin),
    post: (url, body) =>
        superagent.post(encodeURI(`${API_ROOT}${url}`), body).use(tokenPlugin),
    delete: url =>
        superagent.del(encodeURI(`${API_ROOT}${url}`)).use(tokenPlugin),
};

const Vacancies = {
    search: parameters => api.post("/vacancies/search", parameters),
    getById: id => api.get(`/vacancies/${id}`),
    getCategories: () => api.get("/vacancies/categories"),
    getProfessions: () => api.get("/vacancies/professions"),
    getProfessionsInRegion: (cityId) => api.get(`/vacancies/professions?cityId=${cityId}`),
    getProfessionsInCategory : (categoryId) => api.get(`/vacancies/categories/${categoryId}/professions`),
    getProfessionsInCompany: (companyId) => api.get(`/vacancies/professions?companyId=${companyId}`),
    getProfessionsInMetro: (metroId) => api.get(`/vacancies/professions?metroStationId=${metroId}`),
    getProfessionsInTag: (tagId) => api.get(`/vacancies/professions?tagId=${tagId}`),
    searchProfessions: (params) => api.get(`/vacancies/professions`, params),
    getTags: () => api.get("/vacancies/tags"),
    paramsById: id => api.get(`/vacancies/${id}/similar/params`),
    getSimilar: (id) => api.get(`/vacancies/${id}/similar`),
};

const Geo = {
    getRegions: () => api.get("/geo/regions"),
    getCities: () => api.get("/geo/cities"),
    getMetro: () => api.get("/geo/metroStations"),
};

const User = {
    getUser: () => api.get("/user"),
}

const Sitemap = {
    getCompanies: (page, count) => api.get(`/sitemap/companies?page=${page}&itemsPerPage=${count}`),
    getVacancies: (page, count) => api.get(`/sitemap/vacancies?page=${page}&itemsPerPage=${count}`),
}

const Statistics = {
    getTags: (params) => api.get(`/statistics/tags`, params),
}

const Response = async (method) => {
    try{
        const response = await method();
        if(response){
            return JSON.parse(response.text);
        }
    }
    catch (e) {
        //console.log("API request exception: ", e);
    }
    return null;
};

export default {Vacancies, Geo, User, Sitemap, Statistics, Response};