import {Actions} from "../constants/actions";
import avatarom from "../api/avatarom";

export const setSearchParameters = (parameters) =>
    ({type: Actions.SET_SEARCH_PARAMETERS, parameters: parameters});

export const resetSearchParameters = () =>
    ({type: Actions.RESET_SEARCH_PARAMETERS});

export const searchVacancies = (store, parameters = null) =>
    ({type: Actions.SEARCH_VACANCIES, payload: avatarom.Vacancies.search(parameters || store.getState().vacancies.parameters)});

export const searchSimilar = (id) =>
    ({type: Actions.SEARCH_SIMILAR, payload: avatarom.Vacancies.getSimilar(id)});

export const moreVacancies = (store, parameters = null) =>
    ({type: Actions.MORE_VACANCIES, payload: avatarom.Vacancies.search(parameters || store.getState().vacancies.parameters)});

export const getVacancy = (id) =>
    ({type: Actions.GET_VACANCY, payload: avatarom.Vacancies.getById(id)});

export const nextPage = () => ({ type: Actions.NEXT_PAGE });