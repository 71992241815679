const Actions = {
    PAYLOAD_STARTED: "PAYLOAD_STARTED",
    PAYLOAD_FINISHED: "PAYLOAD_FINISHED",
    PAYLOAD_ERROR: "PAYLOAD_ERROR",
    SET_USER: "SET_USER",
    LOGIN: "LOGIN",
    LOGOUT: "LOGOUT",
    SET_SEARCH_PARAMETERS: "SET_SEARCH_PARAMETERS",
    RESET_SEARCH_PARAMETERS: "RESET_SEARCH_PARAMETERS",
    SEARCH_VACANCIES : "SEARCH_VACANCIES",
    SEARCH_SIMILAR: "SEARCH_SIMILAR",
    MORE_VACANCIES: "MORE_VACANCIES",
    GET_VACANCY: "GET_VACANCY",
    NEXT_PAGE: "NEXT_PAGE",
    GET_REGIONS: "GET_REGIONS",
    GET_PROFESSIONS: "GET_PROFESSIONS",
    GET_TAGS: "GET_TAGS",
    GET_CATEGORIES: "GET_CATEGORIES",
    SET_REGION: "SET_REGION",
    SET_PROFESSION: "SET_PROFESSION"
};

export {Actions};